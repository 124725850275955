/* eslint-disable import/first */
// eslint-disable-next-line no-console
console.log('index.tsx loaded');

import 'normalize.css';

import * as Sentry from '@sentry/browser';

import './iePolyfills';

import { client } from './api';
import { APP_LAUNCH_ID_PARAM } from './constants';
import { initializeSentry } from './controllers/initializeSentry';

const init = async () => {
  try {
    const params = new URLSearchParams(window.location.search);
    const appLaunchId = params.get(APP_LAUNCH_ID_PARAM) ?? '';
    const t = Date.now().valueOf().toString();

    const { data, error, response } = await client.GET('/openapi/userConfig', {
      params: {
        query: { appLaunchId, t },
      },
    });

    if (error || !data) {
      if (
        response?.status === 302 || // 302 and 403 are expected responses
        response?.status === 403
      ) {
        throw new Error('User not authenticated.');
      } else {
        throw new Error(error?.[0]?.msg ?? 'An unexpected error occured.');
      }
    }

    window.REGARD_USER_ID = data.regardUserId;
    window.AI_CHAT_ENABLED = data.regardAiChatEnabled;
    window.regardHashedEncounterId = data.regardHashedEncounterId;
    window.regardHashedPatientId = data.regardHashedPatientId;
    window.REGARD_ONE_LINER_IS_COLLAPSED = data.regardOneLinerIsCollapsed;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra('error', error);
      Sentry.captureException('Error getting /openapi/userConfig.');
    });
  } finally {
    // There are user config values required for the application to properly initialize,
    //  so we wait for them before loading the rest of the app.
    import('./app');
  }
};

initializeSentry();
init();
